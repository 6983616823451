import Place from "./Place";
import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { move } from "../places";

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import MapView from "./MapView";

const Trip = () => {
  const places = useSelector((state) => state.places.value);
  const start = places[0];
  const endIndex = places.length - 1;
  const end = places[endIndex];
  const dispatch = useDispatch();

  function handleOnDragEnd(result) {
    if (!result.destination) return;

    dispatch(
      move({
        source: result.source.index,
        destination: result.destination.index,
      })
    );
  }

  return (
    <>
      <Place {...start} index={0} />
      <DragDropContext onDragEnd={handleOnDragEnd}>
        <Droppable droppableId="stops">
          {(provided) => (
            <ul
              className="stops"
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {places.slice(1, endIndex).map(({ id }, i) => {
                const index = i + 1;
                return (
                  <Draggable key={id} draggableId={id} index={index}>
                    {(provided) => (
                      <li
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <Place id={id} index={index} />
                      </li>
                    )}
                  </Draggable>
                );
              })}
              {provided.placeholder}
            </ul>
          )}
        </Droppable>
      </DragDropContext>
      <Place {...end} index={endIndex} />
      <MapView />
    </>
  );
}

export default Trip

