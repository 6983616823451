import React, { useState } from "react";
import { TextField, Autocomplete, Grid, Box, Typography } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { update } from "../places";
import parse from "autosuggest-highlight/parse";

import LocationOnIcon from "@mui/icons-material/LocationOn";

const AddressSearch = ({ id, index, setError }) => {

  const apiKey ="AIzaSyCisaW4QLl6oiaCU0dmLAjOnfQ--IVbXSA"
  const places = useSelector((state) => state.places.value);
  const place = places[index];
  const dispatch = useDispatch();

  const initialAddress = place.address || "";
  const [address, setAddress] = useState(initialAddress);
  const [options, setOptions] = useState([]);
  const isStart = index === 0;
  const isEnd = index === places.length - 1;
  const label = isStart ? "Trip start" : isEnd ? "Trip end" : "Stop"

  const getTz = async (lat, lng) => {
      const apiUrl = `https://maps.googleapis.com/maps/api/timezone/json?location=${lat},${lng}&timestamp=${Math.floor(Date.now() / 1000)}&key=${apiKey}`;
  
      const response = await fetch(apiUrl)
      const data = await response.json()
      return data.timeZoneId
  }

  const handleInputChange = async (event, value, reason) => {
    const autocompleteService = new (await window.google.maps.importLibrary("places")).AutocompleteService()

    if (value) {
      autocompleteService.getPlacePredictions({ input: value }, (results) => {
        setOptions(results || []);
      });
    } else {
      setOptions([]);
    }
  };

  const handleInputSelect = async (event, value, reason) => {
    if (reason === "clear") {
      dispatch(
        update({
          index,
          item: { location: null },
        })
      );
      setAddress(null);
      return;
    }
    setAddress(value.description);
    setOptions([]);
    const geocoder = new (await window.google.maps.importLibrary("geocoding")).Geocoder()
    geocoder.geocode({ address: value.description }, async (results, status) => {
      if (status === "OK" && results.length > 0) {

        const location = results[0].geometry.location;
        const [lat, lng] = [location.lat(), location.lng()]
        const tz = await getTz(lat, lng)
        const placeId = results[0].place_id;
        const formattedAddress = results[0].formatted_address;
 
      
        dispatch(
          update({
            index,
            item: {
              id,
              location: {
                placeId,
                lat,
                lng,
                address: formattedAddress,
                tz,
              }
            },
          })
        );
      } else {
        setError("Address not found");
      }
    });
  };

  return (
    <Autocomplete
      sx={{ width: 300 }}
      getOptionLabel={(option) =>
        typeof option === "string" ? option : option.description
      }
      filterOptions={(x) => x}
      options={options}
      autoComplete
      includeInputInList
      filterSelectedOptions
      value={address}
      noOptionsText="No locations"
      onChange={handleInputSelect}
      onInputChange={handleInputChange}
      isOptionEqualToValue={(option, value) => {
        return option.description === value
      }}
      renderInput={(params) => (
        <TextField {...params} label={label} fullWidth />
      )}
      renderOption={(props, option) => {
        const matches =
          option.structured_formatting.main_text_matched_substrings || [];

        const parts = parse(
          option.structured_formatting.main_text,
          matches.map((match) => [match.offset, match.offset + match.length])
        );

        return (
          <li {...props}>
            <Grid container alignItems="center">
              <Grid item sx={{ display: "flex", width: 44 }}>
                <LocationOnIcon sx={{ color: "text.secondary" }} />
              </Grid>
              <Grid
                item
                sx={{ width: "calc(100% - 44px)", wordWrap: "break-word" }}
              >
                {parts.map((part, index) => (
                  <Box
                    key={index}
                    component="span"
                    sx={{ fontWeight: part.highlight ? "bold" : "regular" }}
                  >
                    {part.text}
                  </Box>
                ))}
                <Typography variant="body2" color="text.secondary">
                  {option.structured_formatting.secondary_text}
                </Typography>
              </Grid>
            </Grid>
          </li>
        );
      }}
    />
  );
};

export default AddressSearch;
