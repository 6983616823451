import React from "react";
import Trip from "./components/Trip";
import { Grid, Alert } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { setError, setInfo, setWarning } from "./alerts";
import { withLayout } from "./components/Layout";
 
 
function App() {
  const dispatch = useDispatch();
  const alerts = useSelector((state) => state.alerts.value);
  return {
    center: (
      <Grid container spacing={2}>
        <Grid item xs={12}></Grid>
        <Grid item xs={12}>
          {alerts?.error && (
            <Alert severity="error" onClose={() => dispatch(setError(null))}>
              {alerts.error}
            </Alert>
          )}
          {alerts?.warning && (
            <Alert
              severity="warning"
              onClose={() => dispatch(setWarning(null))}
            >
              {alerts.warning}
            </Alert>
          )}
          {alerts?.info && (
            <Alert severity="info" onClose={() => dispatch(setInfo(null))}>
              {alerts.info}
            </Alert>
          )}
        </Grid>
        <Grid item xs={12} mt={2}>
          <Trip />
        </Grid>
      </Grid>
    ),
  };
}

export default withLayout(App);
