import * as React from "react";

import { TextField } from "@mui/material";

const NumberInput = ({ min, max, value, setValue, ...props }) => {
  return (
  <TextField
    fullWidth
    type="number"
    inputProps={{ min, max }}
    value={value}
    onChange={(e) => {
      var value = parseInt(e.target.value, 10);

      if (value > max) value = min;
      if (value < min) value = max;

      setValue(value);
    }}
    // variant="outlined"
    {...props}
  />);
};

export default NumberInput;
