import React, {  } from "react";
import { useSelector } from "react-redux";
import { Typography } from "@mui/material";

const Route = ({ id, index }) => {
  const places = useSelector((state) => state.places.value);
  const route = places[index].route;

  return (
    <Typography>
      {route && `${route.distance.text} (${route.duration.text})`}
    </Typography>
  );
};

export default Route;
