import { Avatar } from "@mui/material";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

const PlaceSymbol = ({ id, index }) => {
  const places = useSelector((state) => state.places.value);
  const [symbol, setSymbol] = useState(null);
  const symbols = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";

  useEffect(() => {
    // find all places with a location
    const place = places[index];
    if (!place.location) {
      setSymbol(null)
      return;
    }
    const newIndex =
      places.slice(0, index + 1).filter((p) => p.location).length - 1;
    if (newIndex >= 0) {
      setSymbol(symbols[newIndex]);
    }
  }, [places]);

  if (symbol) {
    return (<Avatar sx={{ width: 30, height: 30, backgroundColor: "#ea4336", }} > {symbol} </Avatar>)
  }

  return <></>

};

export default PlaceSymbol;
