import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setError } from "../alerts";
import { updateBatch } from "../places";
const MapView = () => {
  const [map, setMap] = useState(null);
  const [directions, setDirections] = useState(null);
  const [placesHash, setPlacesHash] = useState("");
  const dispatch = useDispatch();
  const places = useSelector((state) => state.places.value);

  const initMap = async () => {
    const { Map } = await window.google.maps.importLibrary("maps");

    setMap(
      new Map(document.getElementById("map"), {
        zoom: 4,
        center: { lat: -25.27, lng: 133.7 },
        mapId: "5e16e46e3f574d3c",
      })
    );
  };

  const initDirections = async () => {
    const { DirectionsService, DirectionsRenderer } =
      await window.google.maps.importLibrary("routes");

    setDirections({
      service: new DirectionsService(),
      renderer: new DirectionsRenderer(),
    });
  };
  useEffect(() => {
    if (!map) initMap();
    if (!directions) initDirections();
  }, []);

  useEffect(() => {
    updatePlacesHash(places);
  }, [places]);

  useEffect(() => {
    updateMap(places);
  }, [placesHash]);

  const updatePlacesHash = (places) => {
    let newPlacesHash = "";
    places.forEach((p) => {
      newPlacesHash += p.location?.placeId ?? "";
    });

    if (placesHash !== newPlacesHash) {
      setPlacesHash(newPlacesHash);
    }
  };
  const updateMap = async (places) => {
    const start = places[0];
    const end = places.slice(-1)[0];

    if (!start.location || !end.location) return;

    const stops = [];

    places.forEach((e, i) => {
      if (i === 0 || i === places.length - 1 || !places[i].location) return;
      stops.push({ index: i, location: e.location });
    });

    const request = {
      origin: {
        lat: start.location.lat,
        lng: start.location.lng,
      },
      destination: {
        lat: end.location.lat,
        lng: end.location.lng,
      },
      waypoints: stops.map((p) => {
        return {
          location: {
            lat: p.location.lat,
            lng: p.location.lng,
          },
          stopover: true,
        };
      }),
      travelMode: "DRIVING",
    };

    try {
      const result = await directions.service.route(request);
      if (result.status === "OK" && result.routes[0]) {
        directions.renderer.setMap(map);
        directions.renderer.setDirections(result);
      }

      const batch = [];
      // get all legs except last one
      result.routes[0].legs.slice(0, -1).forEach((l, i) => {
        const route = { distance: l.distance, duration: l.duration };
        batch.push({ index: stops[i].index, item: { route } });
      });

      const lastLeg = result.routes[0].legs.slice(-1)[0];
      batch.push({
        index: places.length - 1,
        item: {
          route: {
            distance: lastLeg.distance,
            duration: lastLeg.duration,
          },
        },
      });
      dispatch(updateBatch(batch));
    } catch (e) {
      console.log(e);
      dispatch(setError("Unable to find route. Check Start, End and Stops"));
    }
  };

  return <div id="map" style={{ height: "400px" }}></div>;
};

export default MapView;
