import React, { useEffect, useState } from "react";
import { Grid, Typography, Box } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { update } from "../places";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { DateTimeField } from "@mui/x-date-pickers/DateTimeField";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import "dayjs/locale/en-au";
import _ from "lodash";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

dayjs.extend(utc);
dayjs.extend(timezone);

const TimeEstimate = ({ id, index }) => {
  const places = useSelector((state) => state.places.value);
  const place = places[index];
  const dispatch = useDispatch();
  const [value, setValue] = useState(null);

  const isStart = index === 0;
  const isEnd = index === places.length - 1;

  const handleTimePick = (value) => {
    const departure = value.unix();
    updateEstimate({ departure });
    setValue(value);
  };

  const estimate = place.estimate;

  const updateEstimate = (value) => {
    if (_.isEqual(value, place.estimate)) return;
    dispatch(update({ index, item: { estimate: value } }));
  };

  useEffect(() => {
    if (!place.location) return;
    let prevPlace;
    for (let i = index - 1; i >= 0; i--) {
      prevPlace = places[i];
      if (prevPlace?.location) break;
    }
    let arrival, departure;

    if (prevPlace?.estimate?.departure) {
      arrival =
        prevPlace.estimate.departure + (place.route?.duration?.value ?? 0);
      departure = arrival + (place.stay ?? 0);

      updateEstimate({
        arrival,
        departure,
      });
    }
  }, [places]);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-au">
      <Grid container>
        <Grid item container xs={12} justifyContent={"center"}>
          {isStart && (
            <DateTimePicker
              label={"Depart At"}
              timezone={place?.location?.tz}
              value={value}
              onChange={handleTimePick}
            />
          )}
          {estimate?.arrival && !isStart && (
            <DateTimeField
              label={"Arrive At"}
              readOnly
              timezone={place?.location?.tz}
              value={dayjs((estimate.arrival + 60) * 1000)}
            />
          )}
          {estimate?.departure && !isStart && !isEnd && (
            <DateTimeField
              label={"Depart At"}
              readOnly
              timezone={place?.location?.tz}
              value={dayjs((estimate.departure + 60) * 1000)}
            />
          )}
        </Grid>
        <Grid item container xs={12} justifyContent={"center"}>
          {place?.location?.tz && (estimate?.departure || estimate?.arrival) && (
            <Box display={"flex"} gap={1}>
              <AccessTimeIcon />
              <Typography>{place.location.tz} time</Typography>
            </Box>
          )}
        </Grid>
      </Grid>
    </LocalizationProvider>
  );
};

export default TimeEstimate;
