import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  value: [
    { id: "start" },
    { id: "end" },
  ],
  counter: 0
};

export const places = createSlice({
  name: "places",
  initialState,
  reducers: {
    replace: (state, action) => {
      const items = Array.from(state.value);
      const { index, item } = action.payload;
      items.splice(index, 1);
      items.splice(index, 0, item);
      state.value = items;
    },
    update: (state, action) => {
      const items = Array.from(state.value);
      const { index, item } = action.payload;
      const oldItem = items.splice(index, 1)[0];
      const newItem = {...oldItem, ...item}
      items.splice(index, 0, newItem);
      state.value = items;
    },
    updateBatch: (state, action) => {
      const items = Array.from(state.value);
      const batch = action.payload;
      batch.forEach((e) => {
        const {index, item} = e
        const oldItem = items.splice(index, 1)[0]
        const newItem = {...oldItem, ...item}
        items.splice(index, 0, newItem)
      })
      state.value = items;
    },
    move: (state, action) => {
      const items = Array.from(state.value);
      const { source, destination } = action.payload;
      const [reorderedItem] = items.splice(source, 1);
      items.splice(destination, 0, reorderedItem);
      state.value = items;
    },
    remove: (state, action) => {
      const items = Array.from(state.value);
      const index = action.payload;
      items.splice(index, 1);
      state.value = items;
    },
    add: (state, action) => {
      let items = Array.from(state.value);
      const index = action.payload;
      state.counter += 1
      items.splice(index + 1, 0, {id: state.counter.toString()});
      state.value = items;
    },
  },
});

export const { replace, update, updateBatch, move, remove, add } =
  places.actions;

export default places.reducer;
