import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  value: {
    error: null,
    warning: null,
    info: null,
  },
};

export const alerts = createSlice({
  name: "alerts",
  initialState,
  reducers: {

    setInfo: (state, action) => {
      const info = action.payload;
      state.value = { ...state.value, info };
    },
    setWarning: (state, action) => {
      const warning = action.payload;
      state.value = { ...state.value, warning };
    },
    setError: (state, action) => {
      const error = action.payload;
      state.value = { ...state.value, error };
    },
  },
});

export const { setError, setWarning, setInfo } = alerts.actions;

export default alerts.reducer;
