import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import NumberInput from "./NumberInput";
import { update } from "../places";
import { Grid, Typography } from "@mui/material";

const Stay = ({ id, index }) => {
  const dispatch = useDispatch();
  const places = useSelector((state) => state.places.value);
  const [hrs, setHrs] = useState(0);
  const [days, setDays] = useState(0);
  const isStart = index === 0;
  const isEnd = index === places.length - 1;

  useEffect(() => {
    let total = 0;
    total += hrs ?? 0;
    total += (days ?? 0) * 24;
    total *= 3600;
    dispatch(update({ index, item: { stay: total } }));
  }, [hrs, days, index, dispatch]);
  return (!isStart && !isEnd &&
    <Grid container>
      <Grid item container xs={12}>
        <Grid item xs={6}>
          <NumberInput
            placeholder="Days"
            label="Days"
            value={days}
            min={0}
            setValue={setDays}
          />
        </Grid>
        <Grid item xs={6}>
          <NumberInput
            placeholder="Hours"
            label="Hours"
            value={hrs}
            min={0}
            max={23}
            
            setValue={setHrs}
          />
        </Grid>
      </Grid>
      <Grid item container xs={12} justifyContent="center">
        <Typography>Stay Duration</Typography>
      </Grid>
    </Grid>
  );
};

export default Stay;
