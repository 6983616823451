import Header from "./Header";
import Footer from "./Footer";
import Grid from "@mui/material/Grid";

export const withLayout = (Component) => {
  const LayoutComponent = (props) => {
    const component = Component({ ...props });
    return <Layout {...component} />;
  };

  return LayoutComponent;
};

const Layout = ({
  seo,
  topLeft,
  bottomLeft,
  center,
  topRight,
  bottomRight,
}) => {
  return (
    <Grid container>
      {seo}
      <Grid item xs={12}>
        <Header />
      </Grid>
      <Grid item xs={12} container spacing={2} margin={2}>
        <Grid container item xs={0} lg={2}>
          <Grid item xs={12}>
            {topLeft}
          </Grid>
          <Grid item xs={12}>
            {bottomLeft}
          </Grid>
        </Grid>
        <Grid item xs={12} lg={8}>
          {center}
        </Grid>
        <Grid container item xs={0} lg={2}>
          <Grid item xs={12}>
            {topRight}
          </Grid>
          <Grid item xs={12}>
            {bottomRight}
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Footer />
      </Grid>
    </Grid>
  );
};

export default Layout;
