import React, { useState } from "react";
import AddressSearch from "./AddressSearch";
import { Button, Grid, Alert, Box } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { remove, add } from "../places";
import Route from "./Route";
import PlaceSymbol from "./PlaceSymbol";
import Stay from "./Stay";
import TimeEstimate from "./TimeEstimate";
import DeleteIcon from "@mui/icons-material/Delete";

export default function Place({ id, index }) {
  const places = useSelector((state) => state.places.value);
  const place = places[index];
  const dispatch = useDispatch();
  const handleAddClick = () => {
    if (places.length >= 22) {
      setError("Only 20 stops are allowed");
      return;
    }
    dispatch(add(index));
  };

  const [error, setError] = useState(null);

  const isStart = index === 0;
  const isEnd = index === places.length - 1;
  const handleDeleteClick = () => {
    dispatch(remove(index));
  };
  return (
    <Grid container>
      <Grid item container xs={1} alignItems={"center"}>
        {!isStart && !isEnd && (
          <>
            <Button onClick={handleDeleteClick}>
              <DeleteIcon />
            </Button>
          </>
        )}
      </Grid>
      <Grid item container xs={11}>
        <Grid item xs={12} display={"flex"}>
          <Route id={id} index={index} />
        </Grid>
        <Grid item xs={12} container>
          <Grid item xs={4}>
            <Box display="flex" gap={2} alignItems={"center"}>
              <PlaceSymbol id={id} index={index}/>
              <AddressSearch id={id} index={index} setError={setError} />
            </Box>
          </Grid>
          <Grid item xs={2}>
            <Stay id={id} index={index} />
          </Grid>
          <Grid item xs={4}>
            <TimeEstimate id={id} index={index} />
          </Grid>
          <Grid item xs={2}></Grid>
          {/* <Grid item xs={2}> {JSON.stringify(place)} </Grid> */}
        </Grid>
        <Grid item xs={12} display="flex" justifyContent="left">
          {!isEnd && <Button onClick={handleAddClick}>Add Stop</Button>}
          {error && (
            <Alert severity="error" onClose={() => setError(null)}>
              {error}
            </Alert>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
}
