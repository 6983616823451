import { Typography, Link, Grid } from '@mui/material';
import FavoriteIcon from '@mui/icons-material/Favorite';

const Footer = () => {
  return (
    <Grid container position="fixed" bottom={0}>
      <Grid item xs={6} align="left" container justifyContent="flex-start" alignItems="flex-end">

      </Grid>
      <Grid item xs={6} align="right" container justifyContent="flex-end" alignItems="flex-end">

      </Grid>
    </Grid>
  );
};

export default Footer;
